import React from 'react';
import SEO from 'components/seo';
import TogetherBanner from 'components/togetherBanner';
import Investors from 'components/investors';
import DigitalAgency from './digitalAgency';
// import MeetTheTeam from './meetTheTeam';

function WhoWeAre() {
  return (
    <div className="who-we-are">
      <SEO title="Who We Are" />
      <DigitalAgency />
      {/* <MeetTheTeam /> */}
      {/* <div className="container-fluid py-5">
        <h1 className="font-weight-bolder underscore">Let’s<br /> make it happen</h1>
        <h4 className="font-weight-light text-secondary">
          We believe in bold digital solutions and we aren’t afraid to think unconventionally. If you have a digital technology goal, we want to know about it. If it has never been done before, even better. We love to collaborate with ambitious clients who are looking for disruptive digital products. If you’re looking to change the business processes that influence your company, tell us what you’d like to achieve and we can work together on the solution.
        </h4>
      </div> */}
      <Investors />
      <TogetherBanner />
    </div>
  );
}

export default WhoWeAre;
