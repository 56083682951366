import React from 'react';


function DigitalAgency() {
  return (
    <article className="wrapper">
      <div className="container-fluid pt-5 my-5">
        <div className="row">
          <div className="col-12 col-md-10 col-lg-9 mx-auto">
            <h1 className="font-weight-bolder underscore">A true Innovation<br />Technology company</h1>

            <h4 className="font-weight-light text-secondary">
              Emvelia is an Innovation Technology company focused on creating sustainable solutions that improve life as we know it.
            </h4>

            <h4 className="font-weight-light text-secondary">
            With AI we are able to automate human experiences and build more eco-friendly intelligent solutions, disrupt industries with new technologies and create major social and environmental impact through innovative products and services.
            </h4>
          </div>
        </div>

        <div className="mb-6 text-center">
          <img src="/assets/who-we-are/who-we-are.png" alt=" " className="img-fluid" />
        </div>
      </div>
    </article>
  );
}

export default DigitalAgency;
